// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		0.35em,
		element-height:		2.75em,
		element-margin:		2em,
		element-width:		100%,
		container-width:	100%
	);

// Font.
	$font: (
		family:				('Abel', 'Source Sans Pro', Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:			400,
		weight-bold:		800
	);

// Palette.
	$palette: (
		bg:				#051628,
		fg:				#ffffff,
		fg-bold:			#6f94ba,
		fg-light:			#b2b2b2,
		border:				#efefef,
		border-bg:			#f7f7f7,
		border2:			#dfdfdf, //#40ffed
		border2-bg:			#e7e7e7,

		accent1: (
			bg:				#40ffed,
			fg:				mix(#40ffed, #ffffff, 25%),
			fg-bold:		#ffffff,
			fg-light:		mix(#40ffed, #ffffff, 40%),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2)
		),

		accent2: (
			bg:				#1f1815,
			fg:				rgba(255,255,255,0.5),
			fg-bold:		#ffffff,
			fg-light:		#BEBEBE,
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2)
		)
	);
